import { Button, Icon } from '@aws-amplify/ui-react';
import React, { useContext} from 'react';
import { FacebookContext} from 'context/FacebookContext';
import { FaFacebookF } from 'react-icons/fa6';
import 'assets/styles.css';


function ButtonFacebookLogout() {
  
  const ctx = useContext(FacebookContext);

  return (
    <Button className="fb-button" onClick={ctx.handleFacebookLogout}>
      <Icon as={FaFacebookF} viewBox={{width: 512, height: 512}}/> <span>Logout</span>
    </Button>
  );
}

export default ButtonFacebookLogout;
