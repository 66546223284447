import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Heading,
  ScrollView,
  Tabs,
  TabItem,
  Text,
  View,
  useTheme,
  withAuthenticator,
} from '@aws-amplify/ui-react';
import { ViewPosts } from './ViewPosts';
import { ViewAgents } from './ViewAgents';
import ViewFacebookLogin from "./ViewFacebookLogin";


const App = ({ signOut }) => {

  const { tokens } = useTheme();

  const [activeAgent, setActiveAgent] = useState({});


  // useEffect(() => {
  //   Auth.currentAuthenticatedUser({
  //     bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  //   })
  //     .then((user) => alert(JSON.stringify(user)))
  //     .catch((err) => console.log(err));
  // }, [])
  

  return (
    <View className="App">
      <View
        backgroundColor={tokens.colors.blue[10]}
      >
        <Flex>
          <Button>Home</Button>
          <Button onClick={signOut}>Sign Out</Button>
          <Text>{JSON.stringify(activeAgent)}</Text>
        </Flex>
        <Divider/>
        <Heading level={1}>My App</Heading>
      </View>
      <Grid
        columnGap="0.5rem"
        rowGap="0.5rem"
        templateColumns={{ base: '1fr', large: '1fr 3fr' }}
        templateRows="repeat(1, 1fr)"
      >
        <Card
          rowSpan={{ base: 1, large: 2 }}
          backgroundColor={tokens.colors.blue[20]}
        >
          Left side
          <ViewFacebookLogin/>
        </Card>
        <ScrollView
          backgroundColor={tokens.colors.blue[30]}
          maxHeight="100%"
        >
          <Tabs>
            <TabItem title="Content">
                <Flex direction="column" justifyContent="center">
                  <ViewAgents onAgentSelected={setActiveAgent}>
                    <ViewPosts activeAgent={ activeAgent }/>
                  </ViewAgents>
                </Flex>
              </TabItem>
          </Tabs>
        </ScrollView>
      </Grid>
      <Divider/>
      <View backgroundColor={tokens.colors.blue[40]}>
        © 2023 Semblar AI Limited
      </View>
  </View>
  )
};

export default withAuthenticator(App);