import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from 'aws-amplify';
import {
  Button,
  Flex,
  Heading,
  SelectField,
  Text,
  TextField,
  View,
} from '@aws-amplify/ui-react';
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";


export function ViewPosts( { activeAgent }) {

  const [posts, setPosts] = useState([]);
  const [activePost, setActivePost] = useState({});

  //Run only on the first render
  useEffect(() => {
    fetchPosts();
  }, [activeAgent]);

  
  async function fetchPosts() {
    if (!activeAgent || !Object.keys(activeAgent).length) { return }
    
    try {
      // fetch agent
      const apiData = await API.graphql(
        {
          query: queries.getFacebookAgent,
          variables: { id: activeAgent.id }
        });
      const postsFromAPI = apiData.data.getFacebookAgent.targetPosts.items || [];
      setPosts(postsFromAPI);
      setActivePost(postsFromAPI.length ? postsFromAPI[0] : {});

    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }


  async function createPost(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const postId = form.get("id");
    // create post
    const apiDataPost = await API.graphql({
      query: mutations.createFacebookPost,
      variables: {
        input: {
          postID: postId,
          facebookAgentTargetPostsId: activeAgent.id,  // attach post to agent
        }
      },
    });
    const newPost = apiDataPost.data.createFacebookPost;
    if (newPost) {
      // fetch agent
      const apiDataAgent = await API.graphql({
        query: queries.getFacebookAgent,
        variables: {
          id: activeAgent.id,
        },
      });
      const postsFromAPI = apiDataAgent.data.getFacebookAgent.targetPosts.items || [];
      setPosts(postsFromAPI);
      setActivePost(newPost);
    }
    event.target.reset();
  }

  async function deletePost( { id } ) {
    const NewPosts = posts.filter((post) => post.id !== id);
    // delete post
    await API.graphql({
      query: mutations.deleteFacebookPost,
      variables: {
        input: { id } 
      },
    });
    setPosts(NewPosts);
    setActivePost(NewPosts.length ? NewPosts[0] : {});
  }

  function updateActivePost(event) {
    const selected = posts.find((post) => post.id === event.target.value) || {};
    setActivePost(selected);
  } 


  return (    
    <View>
      <Heading level={2}>Posts</Heading>
      {/* <Text>{activeUser.facebook_post_ids}</Text> */}
      <Text>{(activeAgent.id ? activeAgent.id + "<-" : 'no agent!')}</Text>
      <Flex direction="row" justifyContent="flex-start">
        <View margin="3rem 0" width="50%">
          <Heading level={3}>Select Active Post ({posts.length})</Heading>
          <SelectField
            name="id" value={activePost.id} onChange={updateActivePost}
          >
            {posts.map(
              (post) => (
                <option key={post.id} value={post.id}>{post.postID}</option>
              )
            )}
          </SelectField>
          <Button variation="link" onClick={() => deletePost(activePost)}>
            Delete Post
          </Button>
        </View>
        <View as="form" margin="3rem 0" onSubmit={createPost} width="50%">
          <Heading level={3}>Add Post</Heading>
          <TextField
            name="id"
            placeholder="Facebook Post ID"
            label="Post ID"
            variation="quiet"
            labelHidden
            required
          />
          <Button type="submit" variation="primary">
            Add Post
          </Button>
        </View>
      </Flex>
      <Text>{(activePost.id ? activePost.id : '')}</Text>
    </View>
  );
};