import React, { useContext} from 'react';
import {FacebookContextProvider, FacebookContext} from 'context/FacebookContext';
import ButtonFacebookLogin from 'components/ui/ButtonFacebookLogin';
import ViewFacebookProfile from 'components/ui/ViewFacebookProfile';
import ButtonFacebookLogout from 'components/ui/ButtonFacebookLogout';


function LoginArea() {
  
  const ctx = useContext(FacebookContext);

  return (
    <div>
      {ctx.login ? (
        <>
          <ViewFacebookProfile/>
          <ButtonFacebookLogout/>
        </>
      ) : (
        <ButtonFacebookLogin/>
      )}
    </div>
  );

}


function ViewFacebookLogin() {
  return (
      <FacebookContextProvider>
        <LoginArea/>
      </FacebookContextProvider>
  );
}


export default ViewFacebookLogin;