/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFacebookPost = /* GraphQL */ `
  mutation CreateFacebookPost(
    $input: CreateFacebookPostInput!
    $condition: ModelFacebookPostConditionInput
  ) {
    createFacebookPost(input: $input, condition: $condition) {
      id
      postID
      text
      createdAt
      updatedAt
      facebookAgentTargetPostsId
      __typename
    }
  }
`;
export const updateFacebookPost = /* GraphQL */ `
  mutation UpdateFacebookPost(
    $input: UpdateFacebookPostInput!
    $condition: ModelFacebookPostConditionInput
  ) {
    updateFacebookPost(input: $input, condition: $condition) {
      id
      postID
      text
      createdAt
      updatedAt
      facebookAgentTargetPostsId
      __typename
    }
  }
`;
export const deleteFacebookPost = /* GraphQL */ `
  mutation DeleteFacebookPost(
    $input: DeleteFacebookPostInput!
    $condition: ModelFacebookPostConditionInput
  ) {
    deleteFacebookPost(input: $input, condition: $condition) {
      id
      postID
      text
      createdAt
      updatedAt
      facebookAgentTargetPostsId
      __typename
    }
  }
`;
export const createFacebookAgent = /* GraphQL */ `
  mutation CreateFacebookAgent(
    $input: CreateFacebookAgentInput!
    $condition: ModelFacebookAgentConditionInput
  ) {
    createFacebookAgent(input: $input, condition: $condition) {
      id
      pageID
      pageTitle
      name
      targetPosts {
        items {
          id
          postID
          text
          createdAt
          updatedAt
          facebookAgentTargetPostsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFacebookAgent = /* GraphQL */ `
  mutation UpdateFacebookAgent(
    $input: UpdateFacebookAgentInput!
    $condition: ModelFacebookAgentConditionInput
  ) {
    updateFacebookAgent(input: $input, condition: $condition) {
      id
      pageID
      pageTitle
      name
      targetPosts {
        items {
          id
          postID
          text
          createdAt
          updatedAt
          facebookAgentTargetPostsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFacebookAgent = /* GraphQL */ `
  mutation DeleteFacebookAgent(
    $input: DeleteFacebookAgentInput!
    $condition: ModelFacebookAgentConditionInput
  ) {
    deleteFacebookAgent(input: $input, condition: $condition) {
      id
      pageID
      pageTitle
      name
      targetPosts {
        items {
          id
          postID
          text
          createdAt
          updatedAt
          facebookAgentTargetPostsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
