/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFacebookPost = /* GraphQL */ `
  query GetFacebookPost($id: ID!) {
    getFacebookPost(id: $id) {
      id
      postID
      text
      createdAt
      updatedAt
      facebookAgentTargetPostsId
      __typename
    }
  }
`;
export const listFacebookPosts = /* GraphQL */ `
  query ListFacebookPosts(
    $filter: ModelFacebookPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        text
        createdAt
        updatedAt
        facebookAgentTargetPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFacebookAgent = /* GraphQL */ `
  query GetFacebookAgent($id: ID!) {
    getFacebookAgent(id: $id) {
      id
      pageID
      pageTitle
      name
      targetPosts {
        items {
          id
          postID
          text
          createdAt
          updatedAt
          facebookAgentTargetPostsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFacebookAgents = /* GraphQL */ `
  query ListFacebookAgents(
    $filter: ModelFacebookAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pageID
        pageTitle
        name
        targetPosts {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facebookPostsByPostID = /* GraphQL */ `
  query FacebookPostsByPostID(
    $postID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFacebookPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facebookPostsByPostID(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        text
        createdAt
        updatedAt
        facebookAgentTargetPostsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facebookAgentsByPageID = /* GraphQL */ `
  query FacebookAgentsByPageID(
    $pageID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFacebookAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facebookAgentsByPageID(
      pageID: $pageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pageID
        pageTitle
        name
        targetPosts {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
