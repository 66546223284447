/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://jg54wsxsf5agxntuzvjlbo5j2y.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3ufxqpgzr5d2xeufvqpaao6ofa",
    "aws_cognito_identity_pool_id": "eu-central-1:c738b747-c9c0-4c48-81c8-7d62e82a96b5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_h1HUbPTur",
    "aws_user_pools_web_client_id": "1miqscgm8ndh5i5e4fdqen6muc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "platformsemblarai1956ca211d304f8f8fd47a6c163508171800-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
