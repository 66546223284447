// https://developers.facebook.com/docs/javascript/reference

export const facebookService = {
    init,
    load_sdk,
    //
    login,
    logout,
    checkLoginStatus,
    //
    gqlGet,
    gqlPost,
    gqlDelete
};

async function init(appID, version, onServiceInit) {
    window.fbAsyncInit = function() {
        FB.init({
            appId: appID,
            cookie: true,
            xfbml: true,
            version: version
        });
        onServiceInit();
    };
}

function load_sdk(document) {
    // Load the Facebook SDK asynchronously
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

function checkLoginStatus(onIsLoggedIn, onIsLoggedOut) {
    window.FB.getLoginStatus(function (response) {
        if (response && response.status === 'connected') {
            onIsLoggedIn(response);
        } else {
            onIsLoggedOut();
        }
    });
}


function login(scope, onSuccess, onFail) {
    FB.login(
        function(response) {
            if (response.authResponse) {
                onSuccess(response);
            } else {
                onFail(response);
            }
        },
        {scope: scope}
    );
}


function logout(onSuccess, onFail) {
    // checkLoginStatus(
    //     () => window.FB.logout(onSuccess),
    //     () => onFail()
    // );
    //
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    checkLoginStatus(
        () => deletePermissions(
            () => window.FB.logout(() => onSuccess()),
            () => window.FB.logout(() => onSuccess())
        ),
        () => onFail()
    )    
    // stopAuthenticateTimer();
}


function deletePermissions(onSuccess, onFail) {
    return gqlDelete(
        '/me/permissions',
        () => onSuccess(),
        () => onFail(),
    )
}


function gqlGet(path, params, onSuccess, onError) {
    // https://developers.facebook.com/docs/graph-api/reference/
    FB.api(path, 'get', params, function(response) {
        if (!response || response.error) {
            onError(response?.error);
        } else {
            onSuccess(response);
        }
    })
}


function gqlPost(path, params, onSuccess, onError) {
    FB.api(path, 'post', params, function(response) {
        if (!response || response.error) {
            onError(response?.error);
        } else {
            onSuccess(response);
        }
    })
}

function gqlDelete(path, onSuccess, onError) {
    FB.api(path, 'delete', function(response) {
        if (!response || response.error) {
            onError(response?.error);
        } else {
            onSuccess(response);
        }
    })
}


// async function login() {
//     // login with facebook then authenticate with the API to get a JWT auth token
//     const { authResponse } = await new Promise(window.FB.login);
//     if (!authResponse) return;

//     await apiAuthenticate(authResponse.accessToken);

//     // get return url from location state or default to home page
//     const { from } = history.location.state || { from: { pathname: "/" } };
//     history.push(from);
// }

// async function apiAuthenticate(accessToken) {
//     // authenticate with the api using a facebook access token,
//     // on success the api returns an account object with a JWT auth token
//     const response = await axios.post(`${baseUrl}/authenticate`, { accessToken });
//     if (!response) { return }
//     const account = response.data;
//     accountSubject.next(account);
//     startAuthenticateTimer();
//     return account;
// }


// function getAll() {
//     return axios.get(baseUrl)
//         .then(response => response.data);
// }

// function getById(id) {
//     return axios.get(`${baseUrl}/${id}`)
//         .then(response => response.data);
// }

// async function update(id, params) {
//     const response = await axios.put(`${baseUrl}/${id}`, params);
//     let account = response.data;
//     // update the current account if it was updated
//     if (account.id === accountSubject.value?.id) {
//         // publish updated account to subscribers
//         account = { ...accountSubject.value, ...account };
//         accountSubject.next(account);
//     }
//     return account;
// }

// async function _delete(id) {
//     await axios.delete(`${baseUrl}/${id}`);
//     if (id === accountSubject.value?.id) {
//         // auto logout if the logged in account was deleted
//         logout();
//     }
// }

// // helper methods

// let authenticateTimeout;

// function startAuthenticateTimer() {
//     // parse json object from base64 encoded jwt token
//     const jwtToken = JSON.parse(atob(accountSubject.value.token.split('.')[1]));

//     // set a timeout to re-authenticate with the api one minute before the token expires
//     const expires = new Date(jwtToken.exp * 1000);
//     const timeout = expires.getTime() - Date.now() - (60 * 1000);
//     const { accessToken } = window.FB.getAuthResponse();
//     authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
// }

// function stopAuthenticateTimer() {
//     // cancel timer for re-authenticating with the api
//     clearTimeout(authenticateTimeout);
// }