import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { facebookService } from 'services/facebook.service';


export const FacebookContext = createContext(null);

//const appID = "264414292892326"; //Semblar
const appID = "970383057604695"; //Dev
const version = 'v17.0';
// const configID = "283405004323683";
const scope = 'public_profile,email';
const auto = false;


export function FacebookContextProvider({ children }) {

    const [login, setLogin] = useState(false);
    const [loginData, setLoginData] = useState({});

    // Handle login with Facebook
    const handleFacebookLogin = useCallback(() => {
      facebookService.checkLoginStatus(
        //is arleady logged in -> do nothing
        (response) => onLogin(response),
        //is NOT logged in -> trigger login
        () => facebookService.login(
          scope,
          // success
          (response) => {onLogin(response)},
          // could not log in
          (response) => {
            onLogout();
            //setLoginData({});
          }
        )
      )
    }, [])

    // Handle logout with Facebook
    const handleFacebookLogout = useCallback(() => {
      facebookService.checkLoginStatus(
        //is logged in -> trigger logout
        () => facebookService.logout(
          // success
          () => {onLogout()},
          // could not log out
          () => {}
        ),
        //is already logged out -> do nothing
        () => {}
      )
    }, [])
    
    function onLogin(response){
      setLogin(true);
      setLoginData(response);
    }

    function onLogout(){
      setLogin(false);
      setLoginData({});
    }

    // re-renders change the ref of functions; memoize to avoid deep re-renders
    const contextValue = useMemo(() => ({
        login,
        loginData,
        handleFacebookLogin, // functions need to be defined with useCallback!
        handleFacebookLogout
      }),
      [login, loginData, handleFacebookLogin, handleFacebookLogout]
    );

    // Load Facebook SDK asynchronously
    useEffect(() => {
      facebookService.init(
        appID, version,
        // on service init
        () => {
          facebookService.checkLoginStatus(
            // user is logged in => re-trigger log in flow
            () => handleFacebookLogin(),
            // user is logged out
            auto ? handleFacebookLogin : () => {}
          )
        }
      );
      facebookService.load_sdk(document);
    }, [handleFacebookLogin]);


    return (
      <FacebookContext.Provider value={contextValue}>
        { children }
      </FacebookContext.Provider>
    )
  }