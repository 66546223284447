import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API } from 'aws-amplify';
import {
  Button,
  Flex,
  Heading,
  SelectField,
  Text,
  TextField,
  View,
} from '@aws-amplify/ui-react';
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";


function AgentSelection({ Agents, activeAgent, updateActiveAgent, deleteAgent }) {
  return (
    <View margin="3rem 0" width="50%">
      <Heading level={3}>Select Active Agent ({Agents.length})</Heading>
      <SelectField
        name="id"
        value={activeAgent.id}
        onChange={updateActiveAgent}>
        {Agents.map((Agent, index) => (
          <option key={index} value={Agent.id}>
            {Agent.pageID + '/' + Agent.pageTitle}
          </option>
        ))}
      </SelectField>
      <Button variation="link" onClick={() => deleteAgent(activeAgent)}>
        Delete Agent
      </Button>
    </View>
  );
}

function CreateAgentForm({ onSubmit }) {
  return (
    <View as="form" margin="3rem 0" onSubmit={onSubmit} width="50%">
      <Heading level={3}>Add Agent</Heading>
      <TextField
        name="id"
        placeholder="Facebook Agent ID"
        label="Agent ID"
        variation="quiet"
        labelHidden
        required
      />
      <TextField
        name="name"
        placeholder="Agent Name"
        label="Agent Alias"
        variation="quiet"
        labelHidden
        required
      />
      <Button type="submit" variation="primary">
        Add Agent
      </Button>
    </View>
  );
}

export function ViewAgents({ onAgentSelected, children }) {

  const [Agents, setAgents] = useState([]);
  const [activeAgent, setActiveAgent] = useState({});

  //Run only on the first render
  useEffect(() => {
    fetchAgents()
  }, []);


  async function fetchAgents() {
    try {
      const apiData = await API.graphql({ query: queries.listFacebookAgents });
      const AgentsFromAPI = apiData?.data?.listFacebookAgents?.items || [];
      //const AgentsFromAPI = agents;
      setAgents(AgentsFromAPI);
      const agent = AgentsFromAPI.length ?  AgentsFromAPI[0] : {};
      setActiveAgent(agent);
      sendToParent(agent);
      //return agents;
    } catch (error) {
      console.error("Error fetching agents:", error);
      return []
    }
  }

  async function createFacebookAgent(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      pageID: form.get("id"),
      pageTitle: form.get("name"),
      targetPosts: [],
    };
    try {
      const apiData = await API.graphql({
        query: mutations.createFacebookAgent,
        variables: { input: data },
      });
      const newAgent = apiData?.data?.createFacebookAgent;
      if (newAgent) {
        const UpdatedAgents = [...Agents, newAgent];
        setAgents(UpdatedAgents);
        setActiveAgent(newAgent);
        sendToParent(newAgent);
      }
      event.target.reset();
    } catch (error) {
      console.error("Error creating agent:", error);
    }
  }

  async function deleteFacebookAgent( { id } ) {
    const NewAgents = Agents.filter((Agent) => Agent.id !== id);
    // TODO also delete all posts attatched to agent
    try {
      await API.graphql({
        query: mutations.deleteFacebookAgent,
        variables: { input: { id } },
      });
      setAgents(NewAgents);
      const agent = NewAgents.length ? NewAgents[0] : {};
      setActiveAgent(NewAgents.length ? NewAgents[0] : {});
      sendToParent(agent);
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  }

  async function updateActiveAgent(event) {
      const selected = Agents.find(Agent => Agent.id === event.target.value) || {}; 
      setActiveAgent(selected);
      sendToParent(selected);
  } 

  async function sendToParent(agent) {
    // alert(JSON.stringify(agent));
    onAgentSelected(agent);
  };

  return (
    <View>
      <Heading level={2}>Agents</Heading>
      <Flex direction="row" justifyContent="flex-start">
        <AgentSelection
          Agents={Agents}
          activeAgent={activeAgent}
          updateActiveAgent={updateActiveAgent}
          deleteAgent={deleteFacebookAgent}
        />
        <CreateAgentForm onSubmit={createFacebookAgent} />
      </Flex>
      <Text>{activeAgent.id ? activeAgent.pageID : ""}</Text>
      {children ? children : ""}
    </View>
  );
}