import React, { useCallback, useContext, useEffect, useState} from 'react';
import {FacebookContext} from 'context/FacebookContext';
import { facebookService } from 'services/facebook.service';


function ViewFacebookProfile() {

  const ctx = useContext(FacebookContext);

  const [data, setData] = useState({});
   
  // Fetch user data using Graph API
  const fetchUserData = useCallback( () => {
    facebookService.gqlGet(
      '/me',
      { fields: 'id,name,email' },
      (response) => {setData(response)},
      (error) => {console.log(error)}
    )
  }, []);

  useEffect(
    () => {
      if (ctx.login) {
        fetchUserData()
      }
    },
    [ctx.login, fetchUserData]
  )

  return (
    <div>
      <p>Welcome, {data ? data.name : 'User'}!</p>
      <p>Email: {data ? data.email : 'Not available'}</p>
      {JSON.stringify(data)}
    </div>
  );
}


export default ViewFacebookProfile;